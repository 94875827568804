import * as React from 'react';

import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import styled from 'styled-components';

type LinkProps = Omit<GatsbyLinkProps<undefined>, 'to' | 'state' | 'ref'> & {
  /* Internal urls should match: `/some/page/` – external can be anything */
  url: string;
  /* Open link in a new browser tab */
  openInNewTab?: boolean;
};

/**
 * NOTE: for some reason, calling `styled(Link)` can break dev build,
 * though without affecting prod build...
 * Recommend using <StyledItem as={Link} /> rather than StyledItem = styled(Link)
 * and using `&& {}` inside styles.
 * See: HeaderLogo as an example.
 */
export function Link({
  url,
  activeClassName,
  activeStyle,
  partiallyActive,
  replace,
  openInNewTab,
  children,
  ...props
}: LinkProps) {
  // Assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(url);

  if (internal) {
    return (
      <InternalLink
        to={url}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        partiallyActive={partiallyActive}
        replace={replace}
        {...props}
      >
        {children}
      </InternalLink>
    );
  }
  return (
    <ExternalLink href={url} {...(openInNewTab && { target: '_blank' })} {...props}>
      {children}
    </ExternalLink>
  );
}

const InternalLink = styled(GatsbyLink)``;

const ExternalLink = styled.a``;
