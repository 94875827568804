import * as React from 'react';

import { StileGtagEventName } from '../../../../types/services';

import { Link } from '../Link';
import './Button.css';

type ButtonProps = React.HTMLAttributes<HTMLAnchorElement> & {
  children?: never;
  style?: 'primary' | 'secondary' | 'grey';
  size?: 'small' | 'normal' | 'large';
  label: string | React.ReactNode;
  href?: string;
  gtagEvent?: StileGtagEventName;
};

/**
 * Displays stile Button in different colours
 */
export const Button = ({
  style = 'primary',
  size = 'normal',
  label,
  href = '#',
  className,
  ...props
}: React.PropsWithChildren<ButtonProps>) => {
  return (
    <Link url={href} className={`button --${style} --${size} ${className}`} {...props}>
      {label}
    </Link>
  );
};
